<template>
  <Swiper
    :allow-touch-move
    :auto-height
    :autoplay
    :breakpoints
    :cards-effect
    :controller
    :effect
    :grab-cursor
    :initial-slide
    :loop
    :modules="[Autoplay, Grid, Navigation, Pagination, Zoom]"
    :navigation
    :pagination
    :scrollbar
    :slides-per-view
    :space-between
    :zoom
    @slide-change="({ activeIndex }) => slideChange(activeIndex)"
    @swiper="(slider) => emits('swiper', slider)"
  >
    <SwiperSlide
      v-for="(slide, index) in slides"
      :key="slide.id"
    >
      <div
        v-if="zoom"
        class="swiper-zoom-container"
      >
        <slot v-bind="{ slide, isActive: index === currentSlideIndex }" />
      </div>

      <slot
        v-else
        v-bind="{ slide, isActive: index === currentSlideIndex }"
      />
    </SwiperSlide>
  </Swiper>
</template>

<script lang="ts" setup>
import { Autoplay, Grid, Navigation, Pagination, Zoom } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";

import type { SwiperOptions } from "swiper/types";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

interface Props extends SwiperOptions {
  slides: any[];
}

defineProps<Props>();

const emits = defineEmits<{
  slideChange: [activeIndex: number];
  swiper: [slider];
}>();

const currentSlideIndex = ref(null);

const slideChange = (activeIndex: number) => {
  currentSlideIndex.value = activeIndex;
  emits("slideChange", activeIndex);
};
</script>

<style scoped>
:deep(.swiper-slide) {
  height: auto;
}
</style>
