<template>
  <div class="base-container-first index__sections">
    <div class="index__header">
      <div class="index__header-wrapper">
        <h1 class="index__header-content typography__body1">
          Сервис для автоматизации бизнеса

          <span class="index__header-title typography__headline2">
            Онлайн запись, товары и&nbsp;услуги через мессенджер
          </span>
        </h1>

        <BaseModalButton
          class="base-button-big base-button-first index_button typography__title2"
          :modal="MODAL.AuthRegistration"
        >
          Попробовать бесплатно
        </BaseModalButton>
      </div>

      <BasePicture
        alt=""
        class="index__header-image"
        src="/images/about-main.png"
      />
    </div>

    <ul class="index__advantages">
      <li
        v-for="(bonus, bonusIndex) in BONUSES"
        :key="bonusIndex"
        class="index__advantages-item"
      >
        <span class="advantages__item-icon">
          <BaseIcon2
            :id="bonus.icon.name"
            color="var(--orange-1)"
            :height="bonus.icon.height"
            width="28"
          />
        </span>

        <h4
          class="advantages__item-title typography__headline3"
          v-html="bonus.title"
        />

        <p
          class="advantages__item-description typography__body1"
          v-html="bonus.description"
        />
      </li>
    </ul>
  </div>

  <div class="index__container">
    <section class="base-container-first index__wrapper">
      <div class="index__business">
        <IndexTitle description="Если не&nbsp;найдете нужный функционал&nbsp;&mdash; мы&nbsp;его добавим!">
          <template #title>
            Один Donut,
            <br />
            множество возможностей
          </template>
        </IndexTitle>

        <SharedPageBusiness
          :business-list="BUSINESSES"
          hide-button
          hide-title
        />
      </div>

      <div class="index__wrapper-cards">
        <IndexSliderBig :card-list="CARDS_FIRST" />

        <BaseModalButton
          class="base-button-big base-button-first index_button typography__title2"
          :modal="MODAL.AuthRegistration"
        >
          Стать клиентом Donut
        </BaseModalButton>
      </div>

      <IndexTitle description="Система управления со всем самым нужным перед глазами">
        <template #title>
          Продуманный
          <br />
          и понятный подход
        </template>
      </IndexTitle>

      <IndexSlider :card-list="CARDS_SECOND" />

      <div class="index__try">
        <IndexTitle description="Система управления со всем самым нужным перед глазами">
          <template #title>
            Быстрая покупка
            <br />
            или запись через мессенджер
          </template>

          <template #description>
            <div class="about-title__description">
              Поддерживаемые платформы

              <div class="title__description-socials">
                <BaseIcon2
                  id="icon-telegram-full"
                  height="32"
                  sprite="socials"
                  width="32"
                />

                <BaseIcon2
                  id="icon-instagram-full"
                  height="32"
                  sprite="socials"
                  width="32"
                />

                <BaseIcon2
                  id="icon-snapchat-full"
                  height="32"
                  sprite="socials"
                  width="32"
                />
              </div>
            </div>
          </template>
        </IndexTitle>

        <BaseModalButton
          class="base-button-big base-button-first index_button typography__title2"
          :modal="MODAL.AuthRegistration"
        >
          Попробовать бесплатно
        </BaseModalButton>
      </div>
    </section>
  </div>

  <ul
    id="examples"
    class="base-container-first index__examples"
  >
    <li
      v-for="(section, sectionIndex) in SECTION_CARDS"
      :key="sectionIndex"
    >
      <SharedPageSectionCard
        :card="section"
        :reverse="section.reverse"
      />
    </li>
  </ul>

  <div class="index__container">
    <section class="base-container-first steps__wrapper">
      <IndexTitle description="Поможем с&nbsp;интеграцией на&nbsp;всех этапах">
        <template #title>
          Внедрите Donut
          <br />
          в&nbsp;бизнес всего за&nbsp;4&nbsp;шага
        </template>
      </IndexTitle>

      <IndexSlider
        auto-height
        :card-list="STEPS"
        class="index__cards-slider"
        :is-cards="false"
      />

      <ul class="index__steps">
        <li v-for="(step, stepIndex) in STEPS">
          <IndexStep
            :step
            :step-number="stepIndex + 1"
          />
        </li>
      </ul>

      <BaseModalButton
        class="base-button-big base-button-first index_button typography__title2"
        :modal="MODAL.AuthRegistration"
      >
        Стать клиентом Donut
      </BaseModalButton>
    </section>
  </div>
</template>

<script setup lang="ts">
const BONUSES = [
  {
    description:
      "Создайте свой сайт-приложение для быстрой продажи товаров и&nbsp;простой онлайн-записи на&nbsp;услуги",
    icon: {
      height: "16",
      name: "icon-arrow-graphics",
    },
    title: "Зарабатывайте больше",
  },
  {
    description:
      "Сайт будет доступен как встроенное приложение в&nbsp;мессенджерах или&nbsp;по&nbsp;прямой&nbsp;ссылке",
    icon: {
      height: "13",
      name: "icon-infinity",
    },
    title: "Будьте заметнее",
  },
  {
    description: "Управляйте продажами, записями и&nbsp;чатами через CRM-систему даже со&nbsp;смартфона",
    icon: {
      height: "28",
      name: "icon-wand",
    },
    title: "Повышайте эффективность",
  },
];

const CARDS_FIRST = [
  {
    description:
      "Управляйте индивидуальными и&nbsp;групповыми записями всех филиалов через журнал, а&nbsp;рабочее расписание сотрудников повысит эффективность и&nbsp;прозрачность работы",
    href: "/features/online-record",
    image: "index-card-1",
    title: "Онлайн-запись",
  },
  {
    description:
      "Продавайте товары в&nbsp;удобном каталоге с&nbsp;гибкими настройками, а&nbsp;наша CRM не&nbsp;даст потеряться ни&nbsp;одному заказу и&nbsp;посчитает все остатки на&nbsp;складе",
    href: "/features/shop",
    image: "index-card-2",
    title: "Интернет-магазин",
  },
  {
    description:
      "Предлагайте клиентам любые услуги с&nbsp;быстрой отправкой заявки, а&nbsp;при необходимости включите для них расписание и&nbsp;онлайн-запись",
    href: "/features/services-with-applications",
    image: "index-card-3",
    title: "Услуги с заявками",
  },
  {
    description:
      "Пользуйтесь понятной и&nbsp;функциональной системой управления вашими заказами, записями и&nbsp;заявками",
    href: "/features/crm",
    image: "index-card-4",
    title: "Эффективная CRM",
  },
  {
    description:
      "Общайтесь с&nbsp;клиентами из&nbsp;разных мессенджеров в&nbsp;CRM, а&nbsp;бот поможет ответить на&nbsp;самые популярные вопросы за&nbsp;менеджера",
    href: "/features/chats",
    image: "index-card-5",
    title: "Чаты с клиентами",
  },
];

const CARDS_SECOND = [
  {
    description:
      "Мы&nbsp;продумали почти все&nbsp;&mdash; останется только настроить фирменный цвет и&nbsp;добавить нужную информацию о&nbsp;компании",
    href: "/features/website",
    image: "website-card-2",
    title: "Конструктор сайта",
  },
  {
    description:
      "История всех заказов, записей и&nbsp;заявок в&nbsp;одном удобном профиле с&nbsp;отслеживанием статусов и&nbsp;персональными скидками",
    href: "/features/client-cabinet",
    image: "client-card-2",
    title: "Личный кабинет клиента",
  },
  {
    description: "Лёгкое подключение бота с&nbsp;настройкой автоматических ответов клиенту, уведомлений и&nbsp;команд",
    href: "/features/bot",
    image: "bot-card-2",
    title: "Конструктор бота",
  },
];

const BUSINESSES = [
  "Салон красоты",
  "Магазин одежды",
  "Адвокат",
  "Ресторан",
  "Автомойка",
  "Фитнес",
  "Нотариус",
  "Фотостудия",
  "Бильярдная",
  "Ваш бизнес",
];

// TODO: вставить УРЛ на телеграм бота когда он появится
const SECTION_CARDS = [
  {
    description: `Запись на&nbsp;одиночные или групповые услуги с&nbsp;учётом рабочего графика ваших сотрудников`,
    href: "/",
    image: "index-section-card-1",
    reverse: false,
    title: "Барбершоп",
  },
  {
    description: `Продажа в&nbsp;каталоге с&nbsp;настраиваемыми фильтрами категорий и&nbsp;вариантами товаров по&nbsp;нужным параметрам`,
    href: "/",
    image: "index-section-card-2",
    reverse: true,
    title: "Магазин одежды",
  },
  {
    description: `Получение заявок на&nbsp;услуги с&nbsp;возможностью в&nbsp;любой момент переключить их&nbsp;на&nbsp;онлайн-запись`,
    href: "/",
    image: "index-section-card-3",
    reverse: false,
    title: "Юридическая помощь",
  },
];

const STEPS = [
  {
    color: "#ffffff",
    description: "Расскажем про весь функционал сервиса, который может быть вам полезен",
    title: "Узнаем ваши потребности",
  },
  {
    color: "#dadada",
    description: "Покажем как всё работает и&nbsp;как быстро настроить CRM под ваши потребности",
    title: "Поможем настроить сервис под вас",
  },
  {
    color: "#bababa",
    description: "Поможем с&nbsp;заполнением каталога товаров и&nbsp;услуг, онлайн-расписанием сотрудников",
    title: "Расскажем как перенести данные",
  },
  {
    color: "#979797",
    description: "Избавим ваших сотрудников от&nbsp;долгого изучения работы системы",
    title: "Выдадим&nbsp;инструкции для сотрудников",
  },
];

const route = useRoute();
const { openModal } = useModalStore();

onMounted(() => {
  if (route.query.modal) {
    openModal(route.query.modal as string);
  }
});

definePageMeta({
  layout: "default",
  middleware: [
    async (to) => {
      const { tokenState } = useAuthStore();
      const { $env } = useNuxtApp();

      if ($env.isServer && tokenState.value.accessToken && !to.query.modal) {
        return navigateTo("/client/projects");
      }

      if ($env.isClient && to.query.modal) {
        const { removeRefreshToken } = useAuthToken();

        removeRefreshToken();
      }
    },
  ],
});
</script>

<style scoped>
.index__header {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

@media (--desktop) {
  .index__header {
    padding: 0 24px;
    flex-direction: row;
    align-items: center;
    column-gap: 40px;
  }
}

@media (--laptop) {
  .index__header {
    padding-left: 0;
    padding-right: 0;
  }
}

.index__header-image {
  flex-shrink: 0;
  width: 320px;
  margin: 0 -24px;
  padding: 0 24px;
  height: 100%;
}

@media (--desktop) {
  .index__header-image {
    width: 623px;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    height: 610px;
    max-height: 100%;
  }
}

.index__container {
  background-color: var(--white-1);
}

.index__header-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

@media (--desktop) {
  .index__header-wrapper {
    row-gap: 40px;
    width: 583px;
  }
}

.index__header-content {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  font-weight: 400;
  max-width: 320px;
}

@media (--desktop) {
  .index__header-content {
    max-width: max-content;
    row-gap: 16px;
    font-size: 18px;
  }

  .index__header-title {
    font-size: 60px;
    line-height: 1.1;
  }
}

.index__advantages {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 40px 24px;
  border-radius: 32px;
  background-color: var(--grey-7);
}

@media (--desktop) {
  .index__advantages {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 80px;
    padding: 80px;
    border-radius: 50px;
    margin: 0 40px;
  }
}

.index__advantages-item {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

@media (--desktop) {
  .index__advantages-item {
    row-gap: 16px;
  }
}

.advantages__item-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: var(--white);
}

@media (--desktop) {
  .advantages__item-title {
    max-width: 240px;
    font-weight: 400;
    font-size: 32px;
    line-height: 1.25;
  }
}

.advantages__item-description {
  color: var(--grey-1-5);
}

.index__wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 80px;
  margin: 64px auto 56px;
}

@media (--desktop) {
  .index__wrapper {
    row-gap: 120px;
    margin-top: 96px;
    margin-bottom: 80px;
  }
}

.index__sections {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  margin: 40px auto 80px;
  padding: 0 24px;
}

@media (--desktop) {
  .index__sections {
    row-gap: 60px;
    margin-top: 0;
    margin-bottom: 120px;
    padding-left: 0;
    padding-right: 0;
  }
}

.index__wrapper-cards {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}

@media (--desktop) {
  .index__wrapper-cards {
    align-items: center;
    row-gap: 40px;
  }
}

.index_button {
  max-width: max-content;
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 30px;
}

@media (--desktop) {
  .index_button {
    padding: 16px 40px;
  }

  .steps__wrapper .index_button {
    margin: 0 auto;
  }
}

.index__business {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

@media (--desktop) {
  .index__business {
    row-gap: 32px;
  }
}

.about-title__description {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 12px;
  column-gap: 24px;
  font-weight: 400;
  font-size: 18px;
}

.index__examples {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  margin: 80px 0;
  padding: 0 24px;
}

@media (--desktop) {
  .index__examples {
    padding-left: 0;
    padding-right: 0;
    max-width: 1200px;
    margin: 120px auto;
    row-gap: 60px;
  }
}

.title__description-socials {
  display: flex;
  align-items: center;
  column-gap: 24px;
  color: var(--grey-3);
}

@media (--desktop) {
  .index__cards-slider {
    display: none;
  }
}

.steps__wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  margin: 64px auto 56px;
}

@media (--desktop) {
  .steps__wrapper {
    row-gap: 80px;
    margin-top: 96px;
    margin-bottom: 96px;
  }
}

.index__steps {
  display: none;
}

@media (--desktop) {
  .index__steps {
    display: grid;
    column-gap: 40px;
    grid-template-columns: repeat(4, 1fr);
  }
}

.index__try {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}

@media (--desktop) {
  .index__try {
    row-gap: 40px;
  }
}

:deep(.index__try .about) {
  row-gap: 16px;
}

@media (--desktop) {
  :deep(.index__try .about) {
    row-gap: 24px;
  }
}
</style>
